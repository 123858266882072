$('document').ready(function(){
    generateRecaptchaToken();
})

window.publicGenerateRecaptchaToken = function () {
    generateRecaptchaToken();
}

function generateRecaptchaToken()
{
    var publicKeyRecaptcha = document.querySelector('meta[name="publicKeyRecaptcha"]').content
    grecaptcha.ready(function () { // put my public key
        grecaptcha.execute(publicKeyRecaptcha, {
            action: 'submit'
        }).then(function (token) { //take the token to send
            $('.inputRecaptcha').val(token);
        });
    });
}
