$(document).ready(function(){
    $('#container-background-shoppingBag').click(function(){
        $('.content-bag').css('display','none')
        $('#container-background-shoppingBag').addClass('d-none')
    })

    $('.parent-element').height($('.columnCategories').height());
    $('.infoContact').css("display", "flex");


    // Popup login
    $('#icon-user').click(function(e){
        e.preventDefault();
        $('#modalLoginUser').fadeIn();
    });

    $('#close_login').click(function (e) {
        e.preventDefault();
        $('#modalLoginUser').fadeOut();
    });

    $('#btnLogin').click(function (e) {
        e.preventDefault();
        if (validardatos_login() == 0) {
            $('#formLogin').submit();
        }
    });

    $("#modalLoginUser").click(function(event) {
        if (!$(event.target).closest("#modalLoginUser .modal-content").length && !$(event.target).is("#modalLoginUser .modal-content")) {
          $("#modalLoginUser").fadeOut();
        }
    });

    // Compra bajo demanda
    $(".btn-under-request.desktop").click(function (e) {
        e.preventDefault();
        $("#windowUnderRequest").show();
    });

    $("#underRequestClose").click(function(e){
        e.preventDefault();
        $("#windowUnderRequest").hide();
    });

    $("#underRequestMin").click(function(e){
        e.preventDefault();
        if ($("#windowUnderRequest").hasClass('hide')) {
            $("#windowUnderRequest").removeClass('hide');
            $("#windowUnderRequest").css("bottom", "0");
        } else {
            $("#windowUnderRequest").addClass('hide');
            $("#windowUnderRequest.hide").css("bottom", "-"+$("#underRequestFormContent").outerHeight()+"px");
        }
    });
    // FIN. Compra bajo demanda

    $('.plus-qty').click(function(){

        var qty = parseInt($(this).prev().val())+1;
        $(this).prev().val(qty)
    })
    $('.less-qty').click(function(){
        if( parseInt($(this).next().val())>1){
            var qty = parseInt($(this).next().val())-1;
            $(this).next().val(qty)
        }
    })

    $('#icon-user-mobile').click(function(e){
        e.preventDefault();
        $('#modalLoginUser').fadeIn();
    });

    $('#menu-mobile-image').click(function(){
        shoppingBag(false);
        menuMobile(true);
    })

    $('#shoppingBag').click(function(){
        shoppingBag(true);
        menuMobile(false);
    })

    $('#img-bag').click(function(){
        shoppingBag(false);
    })

    $('#shoppingBagMobile').click(function(){
        shoppingBag(true);
        menuMobile(false);
    })

    function menuMobile(thisItem){
        if(thisItem){
            if($('.menu-mobile').hasClass('noactive')){
                $('.menu-mobile').removeClass('noactive')
                $('.menu-mobile').addClass('active')
            }else{
                $('.menu-mobile').addClass('noactive')
                $('.menu-mobile').removeClass('active')
            }
        }else{
            $('.menu-mobile').addClass('noactive')
            $('.menu-mobile').removeClass('active')
        }
    }

    $('.img-close-header').click(function(){
        menuMobile(false);
    })

    function shoppingBag(thisItem){

        if(thisItem){
            if($('.content-bag').is(':visible')){
                $('.content-bag').css('display','none')
            }else{
                $('.content-bag').css('display','block')
                $('#container-background-shoppingBag').removeClass('d-none')
            }
        }else{
            $('.content-bag').css('display','none')
            $('#container-background-shoppingBag').addClass('d-none')
        }

    }

    $('#btn_send_newsletter').on("click",function(e){

        if(validardatos_newsletter() == 0)
        {

            $('#alert_error_contact_footer').html();
            var html = "";
            publicGenerateRecaptchaToken();
                $.ajax({
                url: $('#routeNewsletter').val(),
                method: 'POST',
                data: {
                    email: $('#email_newsletter').val(),
                    _token: "{{csrf_token()}}",
                    recaptcha: $('#recaptchaNewsletter').val(),
                    _token: $('#_token').val(),
                },

                success: function(data){
                    $('#newsletter_message').text(data.msg);
                    $('#newsletter_message').removeClass('text-danger');
                    $('#newsletter_message').addClass('text-success');
                },
                error: function(XMLHttpRequest, textStatus, errorThrown) {
                    $('#newsletter_message').text(XMLHttpRequest.responseJSON.error);
                    $('#newsletter_message').removeClass('text-success');
                    $('#mail_error_newsletter').removeClass('show');
                    $('#newsletter_message').addClass('text-danger');
                }
            })
        }
    })

    var lastScrollTop = $(this).scrollTop();
    window.onscroll = function(e) {
        if(lastScrollTop < 0) {
            lastScrollTop = 0;
        }

        var st = $(this).scrollTop();
        if (st > lastScrollTop && st > 100) {
            $('#header').addClass('header-hide');
        } else {
            $('#header').removeClass('header-hide');
        }
        if (st > 0) {
            $("#header").addClass('scroll-active');
        } else {
            $("#header").removeClass('scroll-active');
        }
        lastScrollTop = st;
    };
})

function validardatos_newsletter() {
    var testEmail = /^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,4}$/i;
    var error = 0;

    $('#email_newsletter').removeClass('is-invalid');
    $('#mail_error_newsletter').removeClass('show');
    $('#politica_error_newsletter').removeClass('show');

    if (!testEmail.test($('#email_newsletter').val())) {
        error = 1;
        $('#email_newsletter').addClass('is-invalid');
        $('#mail_error_newsletter').addClass('show');
    }

    if ($('#politicaTrue_newsletter').is(':checked')) {
        $('#politicaValue_newsletter').val(1);
    } else {
        error = 1;
        $('#politica_error_newsletter').addClass('show');
    }

    return error;
}

function validardatos_login() {
    var testEmail = /^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,4}$/i;
    var error = 0;
    if (!testEmail.test($('#login_email').val())) {
        error = 1;
        $('#login_email').addClass('is-invalid');
        $('#login_email_error').show();
    } else {
        $('#login_email').removeClass('is-invalid');
        $('#login_email_error').hide();
    }
    if ($('#login_password').val().length < 1) {
        error = 1;
        $('#login_password').addClass('is-invalid');
        $('#login_password_error').show();
    } else {
        $('#login_password').removeClass('is-invalid');
        $('#login_password_error').hide();
    }
    return error;
}


